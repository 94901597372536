import countersKey from '@/config/navigation/vertical/countersKey'

export default [
  {
    title: 'Analytics Dashboard',
    route: 'users-statistic-page',
    icon: 'BarChart2Icon',
    permission: 'users-statistic-page',
  },

  { header: 'Users', permission: ['users-user-page'] },
  {
    title: 'Users',
    route: 'users',
    icon: 'UsersIcon',
    permission: 'users-user-page',
  },
  {
    title: 'Invites',
    route: 'users-invites',
    key: countersKey.userInvites,
    tag: '0',
    tagVariant: 'light-danger',
    icon: 'SendIcon',
    permission: 'user-invites-page',
  },
  { header: 'IDENTITY VERIFICATION', permission: ['kyc-page'] },
  {
    title: 'Dashboard',
    route: 'kyc-dashboard',
    key: 'kyc-dashboard',
    tagVariant: 'light-danger',
    icon: 'Dashboard',
    permission: 'kyc-dashboard',
  },
  {
    title: 'Requests',
    icon: 'Requests',
    key: 'kyc',
    tagVariant: 'light-danger',
    permission: 'kyc-page',
    children: [
      {
        title: 'All',
        route: 'kyc-all',
        icon: 'CircleIcon',
        key: 'kyc-all',
        permission: 'kyc-page',
      },
      {
        title: 'New',
        route: 'kyc-new',
        icon: 'CircleIcon',
        key: 'kyc-new',
        permission: 'kyc-page',
        // tag: '0',
      },
      {
        title: 'In progress',
        route: 'kyc-progress',
        icon: 'CircleIcon',
        key: 'kyc-progress',
        permission: 'kyc-page',
      },
      {
        title: 'Verified',
        route: 'kyc-verified',
        icon: 'CircleIcon',
        key: 'kyc-verified',
        permission: 'kyc-page',
      },
      {
        title: 'Rejected',
        route: 'kyc-rejected',
        icon: 'CircleIcon',
        key: 'kyc-rejected',
        permission: 'kyc-page',
      },
    ],
  },

  {
    header: 'Currency',
    permission: ['market-currency-page'],
  },
  {
    title: 'Crypto Currency',
    route: 'crypto-currency',
    icon: 'ShieldIcon',
    permission: 'crypto-currency-page',
  },
  {
    title: 'Fiat Currency',
    route: 'currency-fiat',
    icon: 'DollarSignIcon',
    permission: 'market-currency-page',
  },

  {
    header: 'Transaction Monitoring',
    permission: ['buy-sell-page', 'wallets-page', 'market-transaction-page'],
  },
  {
    title: 'Wallet',
    icon: 'CreditCardIcon',
    key: 'wallet',
    tagVariant: 'light-danger',
    permission: 'wallets-page',
    children: [
      {
        title: 'CeFi',
        route: 'wallet-cefi',
        icon: 'CircleIcon',
        key: 'wallet-cefi',
        permission: 'wallets-page',
      },

      {
        title: 'DeFi',
        route: 'wallet-defi',
        icon: 'CircleIcon',
        key: 'wallet-defi',
        permission: 'wallets-page',
      },

      {
        title: 'Transactions',
        route: 'wallet-transactions',
        icon: 'CircleIcon',
        key: 'wallet-transactions',
        permission: 'wallets-page',
      },

      // {
      //   title: 'Limits & KYC',
      //   route: 'wallet-limits-kyc',
      //   icon: 'CircleIcon',
      //   permission: 'kyc-config-page',
      // },

      // {
      //   title: 'Threshold',
      //   route: 'threshold',
      //   icon: 'CircleIcon',
      //   key: 'threshold',
      //   permission: 'buy-sell-page',
      // },
    ],
  },

  {
    title: 'Swap',
    route: 'swap',
    icon: 'RefreshCcwIcon',
    key: countersKey.swapTransactions,
    tag: '0',
    tagVariant: 'light-danger',
    permission: 'wallets-page',
  },

  // {
  //   title: 'Buy/sell',
  //   route: 'buy-sell',
  //   icon: 'PercentIcon',
  //   key: countersKey.buySell,
  //   tag: '0',
  //   tagVariant: 'light-danger',
  //   permission: 'buy-sell-page',
  // },

  {
    title: 'AML',
    icon: 'FrownIcon',
    key: 'aml',
    tagVariant: 'light-danger',
    permission: 'buy-sell-page',
    children: [
      {
        title: 'aml.menu.all',
        route: 'aml-all',
        icon: 'CircleIcon',
        key: 'aml-all',
        permission: 'buy-sell-page',
      },
      {
        title: 'aml.menu.new',
        route: 'aml-new',
        icon: 'CircleIcon',
        key: 'aml-new',
        permission: 'buy-sell-page',
        // tag: '0',
      },
      {
        title: 'aml.menu.progress',
        route: 'aml-progress',
        icon: 'CircleIcon',
        key: 'aml-progress',
        permission: 'buy-sell-page',
      },
      {
        title: 'aml.menu.approved',
        route: 'aml-approved',
        icon: 'CircleIcon',
        key: 'aml-approved',
        permission: 'buy-sell-page',
      },
      {
        title: 'aml.menu.declined',
        route: 'aml-declined',
        icon: 'CircleIcon',
        key: 'aml-declined',
        permission: 'buy-sell-page',
      },
    ],
  },

  {
    title: 'Fee',
    route: 'fee',
    icon: 'PercentIcon',
    // key: countersKey.fee,
    // tag: '0',
    tagVariant: 'light-danger',
    permission: 'cefi-fee-management',
  },

  {
    title: 'P2P ',
    icon: 'ShoppingCartIcon',
    key: 'p2p',
    tagVariant: 'light-danger',
    permission: 'market-transaction-page',
    children: [
      {
        title: 'Orders',
        route: 'p2p-orders',
        icon: 'CircleIcon',
        // icon: 'ArchiveIcon',
        key: 'p2p-archive',
        permission: 'orders-page',
      },

      {
        title: 'Deals',
        route: 'p2p-deals',
        icon: 'CircleIcon',
        // icon: 'BriefcaseIcon',
        key: 'p2p-deals',
        permission: 'market-deals-page',
      },
    ],
  },

  { header: 'Settings', permission: ['cold-wallets-page', 'finance-payment-methods-page'] },
  {
    title: 'Hot Wallet ',
    icon: 'ShoppingCartIcon',
    permission: 'cold-wallets-page',
    children: [
      {
        title: 'Hot Wallet',
        route: 'hot-wallet',
        icon: 'CreditCardIcon',
        permission: 'cold-wallets-page',
      },
      // {
      //   title: 'Hot Wallet Buy Sell',
      //   route: 'cold-wallet-buy-sell',
      //   icon: 'CreditCardIcon',
      //   permission: 'cold-wallets-page',
      // },
    ],
  },
  {
    title: 'Payment Methods',
    route: 'payment-methods',
    icon: 'ChevronsRightIcon',
    permission: 'finance-payment-methods-page',
  },
  // {
  //   title: 'Hold',
  //   route: 'hold-settings',
  //   icon: 'ShoppingCartIcon',
  //   permission: 'finance-payment-methods-page',
  // },
  {
    title: 'Hold',
    icon: 'ShoppingCartIcon',
    permission: 'finance-payment-methods-page',
    children: [
      {
        title: 'Hold Settings',
        route: 'hold-settings',
        icon: 'CircleIcon',
        permission: 'cold-wallets-page',
      },
      {
        title: 'Hold Users',
        route: 'hold-users',
        icon: 'CircleIcon',
        permission: 'cold-wallets-page',
      },
      {
        title: 'Hold Winners',
        route: 'hold-leaderboard-winners',
        icon: 'CircleIcon',
        permission: 'cold-wallets-page',
      },
      {
        title: 'Hold Additional Tasks',
        route: 'hold-additional-tasks',
        icon: 'CircleIcon',
        permission: 'cold-wallets-page',
      },
    ],
  },

  { header: 'Admin area', permission: ['admin-user-page', 'users-roles-page'] },
  {
    title: 'Roles',
    route: 'roles',
    icon: 'UserXIcon',
    permission: 'users-roles-page',
  },
  {
    title: 'Create admin',
    route: 'create-admin',
    icon: 'UserCheckIcon',
    permission: 'admin-user-page',
  },

  { header: 'Landing', permission: ['teams-page', 'journey-page', 'faq-page', 'emails-page'] },
  {
    title: 'Team',
    route: 'team',
    icon: 'UsersIcon',
    permission: 'teams-page',
  },
  {
    title: 'FAQ',
    route: 'faq',
    icon: 'HelpCircleIcon',
    permission: 'faq-page',
  },
  {
    title: 'Email',
    route: 'newsletter',
    icon: 'MailIcon',
    permission: 'emails-page',
  },
  {
    title: 'Journey',
    route: 'journey',
    icon: 'MapIcon',
    permission: 'journey-page',
  },
]
